import logo from './images/1.jpg';

function App() {
  return (
    <div className="header">
      <div className="logo-box">
        <img src={logo} alt="Logo" className="logo"></img>
      </div>

      <div className="text-box">
        <div className="heading-primary"></div>
        <div className="heading-primary-main">OmegaZulu LLC</div>
        <div className="heading-primary-sub">Bitcoin is the Future</div>
        <div className="col-1-of-2">About: OmegaZulu is a registered money services business(MSB), money transmitter that operates an instrastate network of automated unidirectional(one-way) cryptocurrency kiosks within Greater Houston, Texas.</div>
            <div className="col-1-of-2">Contact: daniel@omegazulu.com</div>
        </div>

       
          

         
       
     
    </div>
  );
}

export default App;
